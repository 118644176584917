import { useMemo } from 'react'
import { mapify } from 'utils/array'

/**
 * Memoized version of mapify
 */
export const useMapify = <Entity>(
  entities: readonly Entity[],
  matcher: (e: Entity) => string | undefined,
) =>
  useMemo(
    () => mapify(entities, matcher),

    // don't trigger when matcher changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [entities],
  )
