import { notNullable } from 'utils/common'

/**
 * Map every item and add them to result if they aren't nullable
 */
export const notNullableMap = <T, M>(
  array: readonly T[],
  map: (i: T, idx: number, arr: readonly T[]) => M | null | undefined,
) =>
  array
    .map<M | null | undefined>((item, idx, arr) => map(item, idx, arr))
    .filter(notNullable)
