import {
  TableColumn,
  Button,
  TableLoadingState,
  GetRowState,
  Header,
  Popup,
} from '@revolut/ui-kit'
import { useCallback, useEffect, useState } from 'react'
import { useSearchFilter } from 'hooks/useSearchFilter'
import { toPresenceMap } from 'utils/array/toPresenceMap'
import { useSideBox } from 'view/SideBox/SideBox'
import { useMapify } from 'hooks/useMapify'
import {
  filterActiveIds,
  filterSelectedEntities,
  getActiveCount,
  getDefaultSubmitLabel,
} from './utils'
import { EntitiesTable } from './EntitiesTable'

/**
 * Shows table of entities overlay to select
 */
export const SelectEntitiesTableOverlay = <Entity extends { id: string }>(props: {
  onClose: () => void
  onSelect: (entities: Entity[]) => void
  getRowState?: (entity: Entity) => ReturnType<GetRowState<Entity>>
  onSelectUpdate?: (selectedIds: string[]) => void
  preselectedItems?: string[]
  entities: Entity[]
  loadingState: TableLoadingState
  title: string
  columns: TableColumn<Entity>[]
  pending?: boolean
  isSubmitDisabled?: boolean
  SubtitleComponent?: () => JSX.Element
  BannerComponent?: () => JSX.Element
  showErrorWidget?: boolean
  ErrorWidget?: () => JSX.Element
  entitiesTypeLabel: string
  pluralForms: [string, string]
  getSubmitLabel?: (selectedAmount: number) => string
}) => {
  const {
    onClose,
    onSelect,
    getRowState: rowStateGetter,
    onSelectUpdate,
    entities,
    title,
    columns,
    pending,
    preselectedItems,
    loadingState,
    isSubmitDisabled,
    SubtitleComponent,
    BannerComponent,
    entitiesTypeLabel,
    pluralForms,
    getSubmitLabel = getDefaultSubmitLabel,
    ErrorWidget,
    showErrorWidget,
  } = props

  const entityMap = useMapify(entities, (entity) => entity.id)
  const { searchValue, searched, setSearchValue } = useSearchFilter({
    entities,
  })
  const [selectedHash, setSelectedHash] = useState<Record<string, boolean>>({})
  const [showSelected, setShowSelected] = useState(false)
  const switchShowSelected = useCallback(
    () => setShowSelected((v) => !v),
    [setShowSelected],
  )

  // overlay always removes sideboxes
  const { closeSide } = useSideBox()
  useEffect(() => {
    closeSide()
  }, [closeSide])

  useEffect(() => {
    onSelectUpdate?.(filterActiveIds(selectedHash))
  }, [selectedHash, onSelectUpdate])

  useEffect(() => {
    if (preselectedItems?.length) {
      const newHash = toPresenceMap(preselectedItems)
      setSelectedHash(newHash)
    }
  }, [preselectedItems])

  const submitNewEntities = useCallback(() => {
    onSelect(filterSelectedEntities(entities, selectedHash))
  }, [selectedHash, entities, onSelect])

  const selectedCount = getActiveCount(selectedHash, entityMap).length
  const getRowState = useCallback(
    (params: { value: Entity }) => {
      return rowStateGetter?.(params.value) || {}
    },
    [rowStateGetter],
  )

  const onRowClick = useCallback(
    (row: Entity) => {
      setSelectedHash((current) => ({
        ...current,
        [row.id]: !current[row.id],
      }))
    },
    [setSelectedHash],
  )

  const isError = ErrorWidget && showErrorWidget

  return (
    <Popup open size="lg" onClose={onClose} shouldKeepMaxHeight>
      <Header variant="compact">
        <Header.BackButton onClick={onClose} />
        <Header.Title>{title}</Header.Title>
        {SubtitleComponent && (
          <Header.Subtitle>
            <SubtitleComponent />
          </Header.Subtitle>
        )}
      </Header>
      {BannerComponent ? <BannerComponent /> : null}
      {isError ? (
        <ErrorWidget />
      ) : (
        <EntitiesTable
          entitiesTypeLabel={entitiesTypeLabel}
          pluralForms={pluralForms}
          totalCount={entities.length}
          columns={columns}
          data={searched}
          selectedHash={selectedHash}
          setSelectedHash={setSelectedHash}
          switchShowSelected={switchShowSelected}
          showSelected={showSelected}
          searchValue={searchValue}
          onSearchChange={setSearchValue}
          showSelectedSwitcherForce
          loadingState={loadingState}
          getRowState={getRowState}
          onRowClick={onRowClick}
          searchAutoFocus
          isStickyHeader
        />
      )}
      <Popup.Actions horizontal>
        <Button
          elevated
          disabled={!selectedCount || pending || isSubmitDisabled}
          onClick={submitNewEntities}
          pending={pending}
        >
          {getSubmitLabel(selectedCount)}
        </Button>
      </Popup.Actions>
    </Popup>
  )
}
